<template>
  <asterix-form-modal
    title="Add balance"
    closable
    modal-class="sm:max-w-lg"
    @cancel="closeModal"
    @submit="saveNewIncome"
  >
    <template #content>
      <form-row>
        <template #left>
          <sun-label-group text="New Income">
            <currency-selector
              :value="newIncomeMoney"
              class="currency-selector-height h-24"
              placeholder="0.00"
              name="new_income"
              required="required"
              currency-disabled
              default-currency="EUR"
              :min="10"
              @input="newIncomeValue = $event.value"
            />
          </sun-label-group>
        </template>
        <template #right>
          <sun-label-group text="New Balance">
            <div class="flex flex-col items-start">
              <span class="text-gray-800 font-bold text-3xl">{{ newIncomeTotal | currency }}</span>
            </div>
          </sun-label-group>
        </template>
      </form-row>
    </template>
    <template #footer>
      <div class="flex flex-wrap justify-between sm:px-3">
        <save-button
          id="income-submit"
          class="w-full text-sm custom-p-1 sm:w-auto"
          :loading="isLoading"
          text="Pay"
          type="submit"
          variant="pill"
        />
        <sun-button variant="pill" color="white" class="custom-p-1 text-sm hover:bg-gray-100" @click="closeModal">
          Cancel
        </sun-button>
      </div>
    </template>
  </asterix-form-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import FormRow from '@/components/atoms/FormRow/FormRow.vue';
import MoneyAmount from '@/model/shared/MoneyAmount';
import SaveButton from '@/components/atoms/SaveButton';
import AsterixFormModal from '@/components/organisms/shared/AsterixFormModal';
import { ACTIVE_CLIENT } from '@/store/modules/context/keys';
import { Toast } from '@/model/shared/Toast';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { newVirtualTransaction } from '@/services/modules/socialAudience/wallet';

export default {
  name: 'VirtualWalletForm',
  components: {
    FormRow,
    SaveButton,
    CurrencySelector: () => import('@/components/atoms/CurrencySelector/CurrencySelector'),
    AsterixFormModal,
  },
  props: {
    balance: {
      type: Number,
      required: true,
    },
    completedQueryParam: {
      type: String,
      required: true,
    },
    walletId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    newIncomeMoney: MoneyAmount.build({ value: 10, currency: 'EUR' }),
    newIncomeValue: 10,
    isLoading: false,
  }),
  computed: {
    ...mapGetters({
      activeClient: ACTIVE_CLIENT,
    }),
    newIncomeTotal() {
      return this.balance + (this.newIncomeValue || 0);
    },
  },
  methods: {
    ...mapActions({
      createToast: CREATE_TOAST,
    }),
    async saveNewIncome(form) {
      if (!form.valid) return;
      this.isLoading = true;
      try {
        await newVirtualTransaction(this.walletId, { amount: this.newIncomeValue });
        this.createToast(Toast.success('Transaction completed', 'The transaction has been successfully'));
      } catch {
        this.createToast(Toast.error('Transaction error', 'There was an error while performing the transaction'));
      } finally {
        this.isLoading = false;
        this.closeModal();
        this.$emit('update');
      }
    },
    closeModal() {
      this.$emit('cancel');
    },
  },
};
</script>
