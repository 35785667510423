var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      ref: "walletForm",
      attrs: {
        name: "form",
        method: "POST",
        action: _vm.urlActionForm,
        target: "tabPay",
        novalidate: "",
      },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.saveForm.apply(null, arguments)
        },
      },
    },
    [
      _c("asterix-modal", {
        attrs: {
          title: "Add balance",
          closable: "",
          "modal-class": "sm:max-w-lg",
        },
        on: { cancel: _vm.closeModal },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("form-row", {
                  staticClass: "mb-4",
                  scopedSlots: _vm._u([
                    {
                      key: "left",
                      fn: function () {
                        return [
                          _c(
                            "sun-label-group",
                            {
                              staticClass: "h-24",
                              attrs: { text: "New Income" },
                            },
                            [
                              _c("currency-selector", {
                                staticClass: "currency-selector-height",
                                attrs: {
                                  value: _vm.newIncomeMoney,
                                  "currency-disabled": "",
                                  "default-currency": "EUR",
                                  name: "new_income",
                                  min: _vm.minValue,
                                  placeholder: "0.00",
                                  required: "required",
                                },
                                on: {
                                  input: function ($event) {
                                    _vm.newIncomeValue = $event.value
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "right",
                      fn: function () {
                        return [
                          _c(
                            "sun-label-group",
                            { attrs: { text: "New Balance" } },
                            [
                              _c(
                                "div",
                                { staticClass: "flex flex-col items-start" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "text-gray-800 font-bold text-3xl",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("currency")(_vm.newIncomeTotal)
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
                _c("input", {
                  ref: "signatureVersion",
                  attrs: { type: "hidden", name: "Ds_SignatureVersion" },
                }),
                _c("input", {
                  ref: "merchantParameters",
                  attrs: { type: "hidden", name: "Ds_MerchantParameters" },
                }),
                _c("input", {
                  ref: "signature",
                  attrs: { type: "hidden", name: "Ds_Signature" },
                }),
                _c(
                  "p",
                  {
                    staticClass: "my-4 mx-0 italic text-xs sm:max-w-md lg:mx-4",
                  },
                  [
                    _vm._v(
                      " VLN Servicios Publicitarios Integrales, S.L., con domicilio en el Paseo de la Castellana, 130 – 4ª Planta, 28046 Madrid, España y CIF nº B-87495628 e inscrita en el Registro Mercantil de Madrid, tomo 34447, Folio 1, Sección 8, Hoja M 619627. "
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "flex flex-wrap justify-between sm:px-3" },
                  [
                    _c("save-button", {
                      staticClass: "w-full text-sm custom-p-1 sm:w-auto",
                      attrs: {
                        id: "income-submit",
                        loading: _vm.isLoading,
                        text: "Pay",
                        type: "submit",
                        variant: "pill",
                      },
                    }),
                    _c(
                      "sun-button",
                      {
                        staticClass: "custom-p-1 text-sm hover:bg-gray-100",
                        attrs: { variant: "pill", color: "white" },
                        on: { click: _vm.closeModal },
                      },
                      [_vm._v(" Cancel ")]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }