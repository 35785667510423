var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("asterix-form-modal", {
    attrs: { title: "Add balance", closable: "", "modal-class": "sm:max-w-lg" },
    on: { cancel: _vm.closeModal, submit: _vm.saveNewIncome },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c("form-row", {
              scopedSlots: _vm._u([
                {
                  key: "left",
                  fn: function () {
                    return [
                      _c(
                        "sun-label-group",
                        { attrs: { text: "New Income" } },
                        [
                          _c("currency-selector", {
                            staticClass: "currency-selector-height h-24",
                            attrs: {
                              value: _vm.newIncomeMoney,
                              placeholder: "0.00",
                              name: "new_income",
                              required: "required",
                              "currency-disabled": "",
                              "default-currency": "EUR",
                              min: 10,
                            },
                            on: {
                              input: function ($event) {
                                _vm.newIncomeValue = $event.value
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "right",
                  fn: function () {
                    return [
                      _c(
                        "sun-label-group",
                        { attrs: { text: "New Balance" } },
                        [
                          _c(
                            "div",
                            { staticClass: "flex flex-col items-start" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "text-gray-800 font-bold text-3xl",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("currency")(_vm.newIncomeTotal)
                                    )
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "flex flex-wrap justify-between sm:px-3" },
              [
                _c("save-button", {
                  staticClass: "w-full text-sm custom-p-1 sm:w-auto",
                  attrs: {
                    id: "income-submit",
                    loading: _vm.isLoading,
                    text: "Pay",
                    type: "submit",
                    variant: "pill",
                  },
                }),
                _c(
                  "sun-button",
                  {
                    staticClass: "custom-p-1 text-sm hover:bg-gray-100",
                    attrs: { variant: "pill", color: "white" },
                    on: { click: _vm.closeModal },
                  },
                  [_vm._v(" Cancel ")]
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }