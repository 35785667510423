import SelectOption from '@/model/shared/SelectOption';

export const FILTERS = {
  ALL: new SelectOption('ALL'),
  INCOME: new SelectOption('INCOME'),
  EXPENSE: new SelectOption('EXPENSE'),
};

export default {
  filterTranscription: [FILTERS.ALL, FILTERS.INCOME, FILTERS.EXPENSE],
};
