<template>
  <form
    ref="walletForm"
    name="form"
    method="POST"
    :action="urlActionForm"
    target="tabPay"
    novalidate
    @submit.prevent="saveForm"
  >
    <asterix-modal title="Add balance" closable modal-class="sm:max-w-lg" @cancel="closeModal">
      <template #content>
        <form-row class="mb-4">
          <template #left>
            <sun-label-group text="New Income" class="h-24">
              <currency-selector
                :value="newIncomeMoney"
                class="currency-selector-height"
                currency-disabled
                default-currency="EUR"
                name="new_income"
                :min="minValue"
                placeholder="0.00"
                required="required"
                @input="newIncomeValue = $event.value"
              />
            </sun-label-group>
          </template>
          <template #right>
            <sun-label-group text="New Balance">
              <div class="flex flex-col items-start">
                <span class="text-gray-800 font-bold text-3xl">{{ newIncomeTotal | currency }}</span>
              </div>
            </sun-label-group>
          </template>
        </form-row>
        <input ref="signatureVersion" type="hidden" name="Ds_SignatureVersion" />
        <input ref="merchantParameters" type="hidden" name="Ds_MerchantParameters" />
        <input ref="signature" type="hidden" name="Ds_Signature" />
        <p class="my-4 mx-0 italic text-xs sm:max-w-md lg:mx-4">
          VLN Servicios Publicitarios Integrales, S.L., con domicilio en el Paseo de la Castellana, 130 – 4ª Planta,
          28046 Madrid, España y CIF nº B-87495628 e inscrita en el Registro Mercantil de Madrid, tomo 34447, Folio 1,
          Sección 8, Hoja M 619627.
        </p>
      </template>
      <template #footer>
        <div class="flex flex-wrap justify-between sm:px-3">
          <save-button
            id="income-submit"
            class="w-full text-sm custom-p-1 sm:w-auto"
            :loading="isLoading"
            text="Pay"
            type="submit"
            variant="pill"
          />
          <sun-button variant="pill" color="white" class="custom-p-1 text-sm hover:bg-gray-100" @click="closeModal">
            Cancel
          </sun-button>
        </div>
      </template>
    </asterix-modal>
  </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { getTransactionParameters } from '@/services/modules/socialAudience/wallet';
import FormRow from '@/components/atoms/FormRow/FormRow.vue';
import MoneyAmount from '@/model/shared/MoneyAmount';
import IncomeParameters from '@/entities/socialAudience/IncomeParameters';
import SaveButton from '@/components/atoms/SaveButton';
import AsterixModal from '@/components/organisms/shared/AsterixModal';
import { ACTIVE_CLIENT } from '@/store/modules/context/keys';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { Toast } from '@/model/shared/Toast';

export default {
  name: 'WalletForm',
  components: {
    AsterixModal,
    FormRow,
    SaveButton,
    CurrencySelector: () => import('@/components/atoms/CurrencySelector/CurrencySelector'),
  },
  props: {
    balance: {
      type: Number,
      required: true,
    },
    completedQueryParam: {
      type: String,
      required: true,
    },
    walletId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    newIncomeMoney: MoneyAmount.build({ value: 10, currency: 'EUR' }),
    newIncomeValue: 10,
    minValue: 10,
    isLoading: false,
    urlActionForm: process.env.VUE_APP_WALLET_URL,
  }),
  computed: {
    ...mapGetters({
      activeClient: ACTIVE_CLIENT,
    }),
    newIncomeTotal() {
      return this.balance + (this.newIncomeValue || 0);
    },
  },
  methods: {
    ...mapActions({ createToast: CREATE_TOAST }),
    saveForm(event) {
      this.submitForm();
      event.preventDefault();
    },
    async submitForm() {
      if (this.newIncomeValue < this.minValue) return;
      try {
        this.isLoading = true;
        const { urlOk, urlKo } = this.getUrlsOkAndKo();
        const newIncomeParameters = new IncomeParameters(
          new MoneyAmount(this.newIncomeMoney.currency, this.newIncomeValue),
          urlOk,
          urlKo
        );
        const transactionParameters = await getTransactionParameters(this.walletId, newIncomeParameters);

        if (transactionParameters.isValid()) {
          this.setTransactionParametersToForm(transactionParameters);
          const tabIsBlocked = this.checkTabIsBlocked();
          if (tabIsBlocked) {
            this.createToast(Toast.warning('Tab blocked', 'Allow pop-ups on your browser'));
          } else {
            this.$refs.walletForm.submit();
            this.closeModal();
          }
        }
      } catch {
        this.createToast(Toast.error('Transaction error', 'There was an error while performing the transaction'));
      } finally {
        this.isLoading = false;
      }
    },
    getUrlsOkAndKo() {
      const pathOk = this.$router.resolve({
        query: {
          [this.completedQueryParam]: true,
        },
      });
      const pathKo = this.$router.resolve({
        query: {
          [this.completedQueryParam]: false,
        },
      });

      const urlOk = window.location.origin + pathOk.href;
      const urlKo = window.location.origin + pathKo.href;

      return { urlOk, urlKo };
    },
    setTransactionParametersToForm(transactionParameters) {
      this.$refs.signatureVersion.value = transactionParameters.signatureVersion;
      this.$refs.merchantParameters.value = transactionParameters.merchantParameters;
      this.$refs.signature.value = transactionParameters.signature;
    },
    checkTabIsBlocked() {
      const newTab = window.open('', this.$refs.walletForm.target);
      return !newTab || newTab.closed || typeof newTab.closed === 'undefined';
    },
    closeModal() {
      this.$emit('cancel');
    },
  },
};
</script>
