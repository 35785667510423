var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("asterix-section", {
        scopedSlots: _vm._u([
          {
            key: "header-right",
            fn: function () {
              return [
                _c(
                  "sun-button",
                  {
                    staticClass:
                      "px-4 custom-p-1 text-xs bg-gray-700 hover:bg-gray-800",
                    attrs: {
                      variant: "pill",
                      color: "gray",
                      disabled: _vm.loading,
                    },
                    on: {
                      click: function ($event) {
                        _vm.showNewIncomeModal = true
                      },
                    },
                  },
                  [_vm._v(" + Add balance ")]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "flex flex-col md:flex-row mb-2 mx-3" },
                  [
                    _c(
                      "div",
                      { staticClass: "flex flex-col flex-grow items-start" },
                      [
                        _c(
                          "p",
                          {
                            staticClass:
                              "text-xs uppercase font-bold text-gray-500 text-left",
                          },
                          [_vm._v("Balance")]
                        ),
                        _vm.loading
                          ? _c("div", {
                              staticClass:
                                "h-8 w-32 bg-gray-400 animate-pulse rounded-full",
                            })
                          : _c(
                              "span",
                              {
                                staticClass: "text-gray-800 font-bold text-3xl",
                              },
                              [_vm._v(_vm._s(_vm._f("currency")(_vm.balance)))]
                            ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "shadow-md px-4 py-3 flex flex-auto flex-col w-full border-r-2 rounded-lg bg-white mb-3",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "flex flex-col md:flex-row mb-2 mx-2" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex flex-col flex-grow items-start my-2",
                          },
                          [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "uppercase font-bold text-gray-600 text-left",
                              },
                              [_vm._v("Transactions")]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex flex-row flex-grow my-1 justify-center items-end md:mt-0 md:justify-end",
                          },
                          _vm._l(_vm.filterTranscription, function (filter) {
                            return _c(
                              "sun-button",
                              {
                                key: filter.id,
                                staticClass:
                                  "filter-btn w-auto mb-1 mx-1 text-sm border-b-2 focus:shadow-none",
                                class: {
                                  applied:
                                    _vm.filterTranscriptionSelected ===
                                    filter.id,
                                },
                                attrs: {
                                  variant: "text",
                                  color: "transparent",
                                  disabled: _vm.loading,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeFilterTranscription(
                                      filter.id
                                    )
                                  },
                                },
                              },
                              [
                                _c("span", {
                                  domProps: {
                                    textContent: _vm._s(filter.name),
                                  },
                                }),
                              ]
                            )
                          }),
                          1
                        ),
                      ]
                    ),
                    _c(
                      "table",
                      {
                        staticClass: "transition-table",
                        class: { "animate-pulse": _vm.loading },
                      },
                      [
                        _c("colgroup", [
                          _c("col", {
                            staticClass: "table-colgroup-col-icon",
                            attrs: { span: "1" },
                          }),
                          _c("col", { attrs: { span: "1" } }),
                          _c("col", {
                            staticClass: "table-colgroup-col",
                            attrs: { span: "1" },
                          }),
                          _c("col", {
                            staticClass: "table-colgroup-col",
                            attrs: { span: "1" },
                          }),
                          _c("col", {
                            staticClass: "table-colgroup-col-date",
                            attrs: { span: "1" },
                          }),
                        ]),
                        _c("thead", { staticClass: "hidden" }, [
                          _c("tr", [
                            _c("th", [_vm._v("Icon")]),
                            _c("th", [_vm._v("Tipo")]),
                            _c("th", [_vm._v("Value")]),
                            _c("th", [_vm._v("Balance")]),
                            _c("th", [_vm._v("Date")]),
                          ]),
                        ]),
                        _c(
                          "tbody",
                          [
                            _vm._l(_vm.itemsPerPage, function (item) {
                              return _c(
                                "tr",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.loading,
                                      expression: "loading",
                                    },
                                  ],
                                  key: item,
                                },
                                [
                                  _c("td", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "rounded-full p-2 m-1 bg-gray-300 hidden md:block",
                                      },
                                      [
                                        _c("div", {
                                          staticClass:
                                            "bg-gray-300 w-6 h-6 rounded-full",
                                        }),
                                      ]
                                    ),
                                  ]),
                                  _c("td", { staticClass: "text-left" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex flex-row align-center items-center",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "rounded-full p-2 m-1 bg-gray-300 md:hidden mr-4",
                                          },
                                          [
                                            _c("div", {
                                              staticClass:
                                                "bg-gray-300 w-6 h-6 rounded-full",
                                            }),
                                          ]
                                        ),
                                        _c("div", {
                                          staticClass:
                                            "bg-gray-300 w-1/4 rounded h-5",
                                        }),
                                      ]
                                    ),
                                  ]),
                                  _c("td", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-center mt-2 md::text-center md:my-0",
                                      },
                                      [
                                        _c("div", {
                                          staticClass:
                                            "bg-gray-300 w-full rounded h-5",
                                        }),
                                      ]
                                    ),
                                  ]),
                                  _c("td", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-center mt-2 md::text-center md:my-0",
                                      },
                                      [
                                        _c("div", {
                                          staticClass:
                                            "bg-gray-300 w-full rounded h-5",
                                        }),
                                      ]
                                    ),
                                  ]),
                                  _c(
                                    "td",
                                    {
                                      staticClass:
                                        "text-right pr-2 text-gray-600",
                                    },
                                    [
                                      _c("div", {
                                        staticClass:
                                          "bg-gray-300 w-full rounded h-5",
                                      }),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            !_vm.loading
                              ? _vm._l(_vm.items, function (item) {
                                  return _c("tr", { key: item.id }, [
                                    _c("td", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "rounded-full m-1 hidden md:block",
                                          class: {
                                            "bg-green-200 p-3":
                                              item.status.value ===
                                                _vm.completedStatus.value &&
                                              item.type.id ===
                                                _vm.filters.INCOME.id,
                                            "bg-red-200 p-3":
                                              item.status.value ===
                                                _vm.failedStatus.value ||
                                              item.type.id ===
                                                _vm.filters.EXPENSE.id,
                                          },
                                        },
                                        [
                                          item.status.value ===
                                          _vm.completedStatus.value
                                            ? _c("cash-svg", {
                                                staticClass: "w-6",
                                                class: {
                                                  "text-green-600":
                                                    item.type.id ===
                                                    _vm.filters.INCOME.id,
                                                  "text-red-600":
                                                    item.type.id ===
                                                    _vm.filters.EXPENSE.id,
                                                },
                                              })
                                            : _c("close-svg", {
                                                staticClass: "text-red-600",
                                              }),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("td", { staticClass: "text-left" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex flex-row align-center items-center",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "rounded-full m-1 md:hidden mr-4",
                                              class: {
                                                "bg-green-200 p-3":
                                                  item.status.value ===
                                                  _vm.completedStatus.value,
                                                "bg-red-200 p-3":
                                                  item.status.value ===
                                                  _vm.failedStatus.value,
                                              },
                                            },
                                            [
                                              item.type.id ===
                                              _vm.filters.INCOME.id
                                                ? _c("cash-svg", {
                                                    staticClass: "w-6",
                                                    class: {
                                                      "text-green-600":
                                                        item.type.id ===
                                                        _vm.filters.INCOME.id,
                                                      "text-red-600":
                                                        item.type.id ===
                                                        _vm.filters.EXPENSE.id,
                                                    },
                                                  })
                                                : _c("close-svg", {
                                                    staticClass: "text-red-600",
                                                  }),
                                            ],
                                            1
                                          ),
                                          _c("span", {
                                            domProps: {
                                              textContent: _vm._s(
                                                `${item.type.name} ${item.status.name}`
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                    ]),
                                    _c("td", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-center mt-2 md:my-0 font-bold",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("currencyNotRounded")(
                                                  item.money.value,
                                                  item.money.currency
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("td", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-center mt-2 md:my-0",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("currencyNotRounded")(
                                                  item.balance.value,
                                                  item.balance.currency
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("td", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-right pr-2 text-gray-600",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("applyDateFormatter")(
                                                  item.createdAt
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ])
                                })
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]
                    ),
                    !_vm.loading && !_vm.items.length
                      ? _c("asterix-no-data", {
                          staticClass: "bg-white text-center",
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "title",
                                fn: function () {
                                  return [_vm._v(" Wallet ")]
                                },
                                proxy: true,
                              },
                              {
                                key: "subtitle",
                                fn: function () {
                                  return [_vm._v(" No transaction still. ")]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            572249743
                          ),
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c("sun-pagination-page", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.loading,
                      expression: "!loading",
                    },
                  ],
                  key: _vm.currentPage,
                  staticClass: "my-6",
                  attrs: {
                    "total-pages": _vm.totalPages,
                    "total-items": _vm.totalItems,
                    "current-page": _vm.currentPage,
                    value: _vm.itemsPerPage,
                    "options-per-page": _vm.optionsPerPage,
                  },
                  on: {
                    changePage: function ($event) {
                      return _vm.goToPage({ page: $event })
                    },
                    changePerPage: _vm.changeItemsPerPage,
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm.isVirtualWallet
        ? _c(
            "div",
            [
              _vm.showNewIncomeModal
                ? _c("virtual-wallet-form", {
                    ref: "walletForm",
                    attrs: {
                      id: "wallet-form",
                      "wallet-id": _vm.currentWalletId,
                      balance: _vm.balance,
                      "completed-query-param": _vm.completedQueryParam,
                    },
                    on: {
                      cancel: function ($event) {
                        _vm.showNewIncomeModal = false
                      },
                      update: _vm.getWalletInfo,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _c(
            "div",
            [
              _vm.showNewIncomeModal
                ? _c("wallet-form", {
                    ref: "walletForm",
                    attrs: {
                      id: "wallet-form",
                      "wallet-id": _vm.currentWalletId,
                      balance: _vm.balance,
                      "completed-query-param": _vm.completedQueryParam,
                    },
                    on: {
                      cancel: function ($event) {
                        _vm.showNewIncomeModal = false
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }