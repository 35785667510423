import { getRawUserById } from '../../Core/user/getRawUserById';
import { USER_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import socialAudienceEntityParser from '@/entities/socialAudience/SocialAudienceEntityParser';
import ResponseBuilder from '@/model/shared/ResponseBuilder';

/**
 * @param {string} userId
 */
export const getResources = userId => ({
  [USER_RESOURCE]: userId,
});

/**
 *
 * @param {string} userId
 * @param {QueryParamsBuilder} params
 * @returns {Promise.<{data:UserCore, meta:import('@/model/shared/ResponseBuilder').EndpointMeta>}
 */
export async function getUserById(userId, params = new QueryParamsBuilder()) {
  const { data } = await getRawUserById(userId, params);
  return new ResponseBuilder(socialAudienceEntityParser.parse(data), data.meta).build();
}
