import MoneyAmount from '@/model/shared/MoneyAmount';

export default class IncomeParameters {
  /**
   * @param {MoneyAmount} money
   * @param {String} urlOk
   * @param {String} urlKo
   */
  constructor(money = MoneyAmount(0), urlOk = null, urlKo = null) {
    this.money = money;
    this.urlOk = urlOk;
    this.urlKo = urlKo;
  }

  payload() {
    return {
      ...this,
      money: this.money.value,
    };
  }
}
