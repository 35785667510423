<template>
  <div>
    <asterix-section>
      <template #header-right>
        <sun-button
          variant="pill"
          class="px-4 custom-p-1 text-xs bg-gray-700 hover:bg-gray-800"
          color="gray"
          :disabled="loading"
          @click="showNewIncomeModal = true"
        >
          + Add balance
        </sun-button>
      </template>

      <template #content>
        <div class="flex flex-col md:flex-row mb-2 mx-3">
          <div class="flex flex-col flex-grow items-start">
            <p class="text-xs uppercase font-bold text-gray-500 text-left">Balance</p>
            <div v-if="loading" class="h-8 w-32 bg-gray-400 animate-pulse rounded-full" />
            <span v-else class="text-gray-800 font-bold text-3xl">{{ balance | currency }}</span>
          </div>
          <!-- WAITING SPECIFICATION FOR AUTOMATIC RECHARGE
          <div class="flex flex-col flex-grow items-start md:items-end mb-2 md:mb-0">
            <p class="text-xs uppercase font-bold text-gray-500 text-left">Automatic income recharge</p>
            <sun-toggle
              v-model="automaticRecharge"
              name="automatic_recharge"
              class="h-full"
              @change="changeAutomaticRecharge"
            />
          </div>
          -->
        </div>
        <div class="shadow-md px-4 py-3 flex flex-auto flex-col w-full border-r-2 rounded-lg bg-white mb-3">
          <div class="flex flex-col md:flex-row mb-2 mx-2">
            <div class="flex flex-col flex-grow items-start my-2">
              <p class="uppercase font-bold text-gray-600 text-left">Transactions</p>
            </div>
            <div class="flex flex-row flex-grow my-1 justify-center items-end md:mt-0 md:justify-end">
              <sun-button
                v-for="filter in filterTranscription"
                :key="filter.id"
                variant="text"
                color="transparent"
                class="filter-btn w-auto mb-1 mx-1 text-sm border-b-2 focus:shadow-none"
                :class="{ applied: filterTranscriptionSelected === filter.id }"
                :disabled="loading"
                @click="changeFilterTranscription(filter.id)"
              >
                <span v-text="filter.name"></span>
              </sun-button>
            </div>
          </div>

          <table class="transition-table" :class="{ 'animate-pulse': loading }">
            <colgroup>
              <col span="1" class="table-colgroup-col-icon" />
              <col span="1" />
              <col span="1" class="table-colgroup-col" />
              <col span="1" class="table-colgroup-col" />
              <col span="1" class="table-colgroup-col-date" />
            </colgroup>
            <thead class="hidden">
              <tr>
                <th>Icon</th>
                <th>Tipo</th>
                <th>Value</th>
                <th>Balance</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              <!-- loading -->
              <tr v-for="item in itemsPerPage" v-show="loading" :key="item">
                <td>
                  <div class="rounded-full p-2 m-1 bg-gray-300 hidden md:block">
                    <div class="bg-gray-300 w-6 h-6 rounded-full"></div>
                  </div>
                </td>
                <td class="text-left">
                  <div class="flex flex-row align-center items-center">
                    <div class="rounded-full p-2 m-1 bg-gray-300 md:hidden mr-4">
                      <div class="bg-gray-300 w-6 h-6 rounded-full"></div>
                    </div>
                    <div class="bg-gray-300 w-1/4 rounded h-5"></div>
                  </div>
                </td>

                <td>
                  <div class="text-center mt-2 md::text-center md:my-0">
                    <div class="bg-gray-300 w-full rounded h-5"></div>
                  </div>
                </td>

                <td>
                  <div class="text-center mt-2 md::text-center md:my-0">
                    <div class="bg-gray-300 w-full rounded h-5"></div>
                  </div>
                </td>

                <td class="text-right pr-2 text-gray-600">
                  <div class="bg-gray-300 w-full rounded h-5"></div>
                </td>
              </tr>

              <!-- -->
              <template v-if="!loading">
                <tr v-for="item in items" :key="item.id">
                  <td>
                    <div
                      class="rounded-full m-1 hidden md:block"
                      :class="{
                        'bg-green-200 p-3':
                          item.status.value === completedStatus.value && item.type.id === filters.INCOME.id,
                        'bg-red-200 p-3':
                          item.status.value === failedStatus.value || item.type.id === filters.EXPENSE.id,
                      }"
                    >
                      <cash-svg
                        v-if="item.status.value === completedStatus.value"
                        class="w-6"
                        :class="{
                          'text-green-600': item.type.id === filters.INCOME.id,
                          'text-red-600': item.type.id === filters.EXPENSE.id,
                        }"
                      />
                      <close-svg v-else class="text-red-600" />
                    </div>
                  </td>
                  <td class="text-left">
                    <div class="flex flex-row align-center items-center">
                      <div
                        class="rounded-full m-1 md:hidden mr-4"
                        :class="{
                          'bg-green-200 p-3': item.status.value === completedStatus.value,
                          'bg-red-200 p-3': item.status.value === failedStatus.value,
                        }"
                      >
                        <cash-svg
                          v-if="item.type.id === filters.INCOME.id"
                          class="w-6"
                          :class="{
                            'text-green-600': item.type.id === filters.INCOME.id,
                            'text-red-600': item.type.id === filters.EXPENSE.id,
                          }"
                        />
                        <close-svg v-else class="text-red-600" />
                      </div>
                      <span v-text="`${item.type.name} ${item.status.name}`"></span>
                    </div>
                  </td>

                  <td>
                    <div class="text-center mt-2 md:my-0 font-bold">
                      {{ item.money.value | currencyNotRounded(item.money.currency) }}
                    </div>
                  </td>

                  <td>
                    <div class="text-center mt-2 md:my-0">
                      {{ item.balance.value | currencyNotRounded(item.balance.currency) }}
                    </div>
                  </td>

                  <td>
                    <div class="text-right pr-2 text-gray-600">
                      {{ item.createdAt | applyDateFormatter }}
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
          <asterix-no-data v-if="!loading && !items.length" class="bg-white text-center">
            <template #title> Wallet </template>
            <template #subtitle> No transaction still. </template>
          </asterix-no-data>
        </div>

        <sun-pagination-page
          v-show="!loading"
          :key="currentPage"
          :total-pages="totalPages"
          :total-items="totalItems"
          :current-page="currentPage"
          :value="itemsPerPage"
          :options-per-page="optionsPerPage"
          class="my-6"
          @changePage="goToPage({ page: $event })"
          @changePerPage="changeItemsPerPage"
        />
      </template>
    </asterix-section>
    <div v-if="isVirtualWallet">
      <virtual-wallet-form
        v-if="showNewIncomeModal"
        id="wallet-form"
        ref="walletForm"
        :wallet-id="currentWalletId"
        :balance="balance"
        :completed-query-param="completedQueryParam"
        @cancel="showNewIncomeModal = false"
        @update="getWalletInfo"
      ></virtual-wallet-form>
    </div>
    <div v-else>
      <wallet-form
        v-if="showNewIncomeModal"
        id="wallet-form"
        ref="walletForm"
        :wallet-id="currentWalletId"
        :balance="balance"
        :completed-query-param="completedQueryParam"
        @cancel="showNewIncomeModal = false"
      ></wallet-form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { activeClientMixin } from '@/mixins/common/activeClientMixin';
import { CONTEXTS } from '@/model/shared/contexts';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { ROLES } from '@/model/shared/roles';
import { Toast } from '@/model/shared/Toast';
import { USER } from '@/store/modules/auth/keys';
import AsterixSection from '@/components/templates/AsterixSection';
import CONFIG, { FILTERS } from './config';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import { ACTIVE_CLIENT } from '@/store/modules/context/keys';
import { getWalletById, changeAutomaticRecharge } from '@/services/modules/socialAudience/wallet';
import { applyDateFormatters } from '@/filters/dateFilters';
import { spanishDateFormatter } from '@/model/shared/DateFormatter';
import { queryParamsMixin } from '@/mixins/common/queryParamsMixin';
import WalletForm from '@/components/organisms/modules/socialAudience/wallet/WalletForm';
import VirtualWalletForm from '@/components/organisms/modules/socialAudience/wallet/VirtualWalletForm';
import apiRequest from '@/utils/apiRequest';
import { COMPLETED_STATUS, FAILED_STATUS } from '@/model/modules/socialAudience/wallet/TransactionStatus';
import { VIRTUAL_TYPE } from '@/model/modules/socialAudience/wallet/WalletType';
import { getSettings } from '@/services/modules/socialAudience/user';
import currency from '@/filters/currency';

export default {
  name: 'WalletList',
  components: {
    AsterixSection,
    AsterixNoData: () => import('@/components/organisms/shared/AsterixNoData'),
    CloseSvg: () => import('@/components/icons/CloseSvg.vue'),
    CashSvg: () => import('@/components/icons/CashSvg.vue'),
    WalletForm,
    VirtualWalletForm,
  },
  filters: {
    applyDateFormatter: date => applyDateFormatters(date, spanishDateFormatter),
    currencyNotRounded: value => {
      if (value === null || value === undefined) return 'N/A';
      value = value.toString();
      return currency(value.slice(0, value.indexOf('.') + 3));
    },
  },
  mixins: [activeClientMixin, queryParamsMixin],
  props: {
    walletId: { type: String, default: () => null },
  },
  data: () => ({
    filters: FILTERS,
    filterTranscription: CONFIG.filterTranscription,
    filterTranscriptionSelected: FILTERS.ALL.id,
    optionsPerPage: [10, 25, 50, 100],
    currentPage: 1,
    totalItems: 0,
    totalPages: 1,
    loading: false,
    items: [],
    showNewIncomeModal: false,
    balance: 0,
    automaticRecharge: false,
    walletTypeId: null,
    completedQueryParam: 'completed',
    completedStatus: COMPLETED_STATUS,
    failedStatus: FAILED_STATUS,
    settings: null,
  }),
  computed: {
    ...mapGetters({
      user: USER,
      activeClient: ACTIVE_CLIENT,
    }),
    isVirtualWallet() {
      return this.walletTypeId === VIRTUAL_TYPE.id;
    },
    contextRol() {
      return this.user?.contextRoles.find(contextRole => contextRole.context === CONTEXTS.SOCIAL_AUDIENCE.id).role;
    },
    currentWalletId() {
      if (this.contextRol === ROLES.CLIENT.id) {
        return this.settings.get('wallet').id;
      }
      return this.walletId;
    },
  },
  async created() {
    await this.loadingValueFromQueryString();

    if (this.contextRol === ROLES.CLIENT.id) {
      this.settings = await getSettings();
    }

    await this.getWalletInfo();
    this.checkPossibleTransaction();
  },
  methods: {
    ...mapActions({ createToast: CREATE_TOAST }),
    loadingValueFromQueryString() {
      this.currentPage = parseInt(this.queryParams.page) || 1;
      this.itemsPerPage = parseInt(this.queryParams.itemsPerPage) || 10;

      const filter = this.queryParams.filter;
      this.filterTranscriptionSelected = FILTERS[filter]?.id || FILTERS.ALL.id;
    },
    async getWalletInfo() {
      this.loading = true;

      await apiRequest(async () => {
        const params = new QueryParamsBuilder(this.currentPage, this.itemsPerPage);
        if (this.filterTranscriptionSelected !== FILTERS.ALL.id) {
          params.addFilter('type', this.filterTranscriptionSelected);
        }
        params.addInclude('transactions');
        const { data, meta } = await getWalletById(this.currentWalletId, params);

        this.balance = data.balance;
        this.automaticRecharge = data.automaticRecharge;
        this.walletTypeId = data.walletType.id;
        this.items = data.transactions;
        this.totalPages = meta?.totalPages || 1;
        this.totalItems = meta?.totalResults || data?.length;
      }).catch(error => {
        this.createToast(Toast.error('Comment not get transitions', error.message));
      });

      this.loading = false;
    },
    async goToPage({ page }) {
      this.currentPage = page;
      this.addQueryParams({ page });
      await this.queryParamsRouterReplace();
      await this.getLoadItems();
    },
    async changeItemsPerPage(value) {
      if (parseInt(value) !== parseInt(this.queryParams?.itemsPerPage)) {
        this.addQueryParams({ itemsPerPage: value, page: 1 });
        await this.queryParamsRouterReplace();
        this.itemsPerPage = value;
        this.currentPage = 1;
        await this.getLoadItems();
      }
    },
    async changeFilterTranscription(filter) {
      if (this.filterTranscriptionSelected === filter) {
        this.filterTranscriptionSelected = FILTERS.ALL.id;
      } else {
        this.filterTranscriptionSelected = filter;
      }
      this.currentPage = 1;
      this.addQueryParams({ filter: this.filterTranscriptionSelected, page: 1 });
      await this.queryParamsRouterReplace();
      await this.getLoadItems();
    },
    async changeAutomaticRecharge(enableOrDisable) {
      try {
        await changeAutomaticRecharge(this.activeClient.id, enableOrDisable);
        this.createToast(Toast.success(`${enableOrDisable ? 'Enabled' : 'Disabled'} automatic recharge.`));
      } catch (e) {
        this.createToast(Toast.error('Error to change automatic recharge.', e.message));
        this.automaticRecharge = !enableOrDisable;
      }
    },
    checkPossibleTransaction() {
      if (Object.prototype.hasOwnProperty.call(this.queryParams, this.completedQueryParam)) {
        const completed = this.queryParams[this.completedQueryParam] === 'true';
        if (completed) {
          this.createToast(Toast.success('Transaction completed', 'The transaction has been successfully'));
        } else {
          this.createToast(Toast.error('Transaction failed', 'There was an error in the transaction'));
        }
      }
      this.removeQueryParam(this.completedQueryParam);
      this.queryParamsRouterReplace();
    },
  },
};
</script>

<style scoped>
::v-deep .filter-btn {
  @apply p-1 pb-0;
  @apply border-transparent;
}
::v-deep .filter-btn span {
  @apply text-gray-500;
  @apply text-sm;
}
::v-deep .filter-btn.applied {
  @apply border-orange-500;
  @apply border-b-2;
}
::v-deep .filter-btn.applied span {
  @apply text-orange-500;
}
::v-deep .filter-btn:hover span,
::v-deep .filter-btn:hover .applied span {
  @apply text-orange-500;
}

.table-colgroup-col-icon {
  width: 46px;
}
.table-colgroup-col {
  width: 130px;
}
.table-colgroup-col-date {
  width: 100px;
}
.currency-selector-height {
  height: 74px;
}

table.transition-table {
  width: 100%;
  border-collapse: collapse;
}
table.transition-table.loading {
  @apply animate-pulse;
}
table.transition-table tbody tr {
  @apply border-b-2;
}
table.transition-table.animate-pulse tbody tr td div {
  @apply bg-gray-300 rounded-full;
}
table.transition-table tbody tr:last-child {
  @apply border-0;
}

@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Force table to not be like tables anymore */
  table,
  tbody,
  th,
  td,
  tr {
    display: block;
  }
  table,
  tbody,
  tr {
    margin-bottom: 20px;
  }
  table,
  tbody,
  tr:last-child {
    margin-bottom: 0px;
  }
}
</style>
